

const termsPolicy = () => {
    
    return (
        <div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 my-5 pt-2 text-muted">
                            <h2 className="text-primary">Terms and Conditions</h2>
                            <p className="">By using Bookshop.com.pk (‘Site’) you agree to these terms and conditions, privacy policy, returns and refund policy of the Site. Please read the Terms and Conditions carefully before using Bookshop.com.pk.</p>
                            <h6 className="fw-normal mt-3 text-dark"><b>1. INTRODUCTION</b></h6>
                            <p>Welcome to Bookshop.com.pk also hereby known as “we”, “us” or “Book Shop”. We are an online marketplace and these are the terms and conditions governing your access and use of Book Shop along with its related sub-domains, sites, mobile app, services and tools (the “Site”). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the “User Agreement”). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operated by Indus Stationer(CopyLine) (SMC Private limited) (Registration Number: ‘8923298)</p>
                            <p>The Site reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.</p>
                            <h6 className="fw-normal mt-5 text-dark"><b>2. CONDITIONS OF USE</b></h6>
                            <h6 className="fw-normal mt-3 text-dark">A. YOUR ACCOUNT</h6>
                            <p>To access certain services offered by the platform, we may require that you create an account with us or provide personal information to complete the creation of an account. We may at any time in our sole and absolute discretion, invalidate the username and/or password without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by, caused by, arising out of, in connection with or by reason of such request or invalidation.</p>
                            <p>You are responsible for maintaining the confidentiality of your user identification, password, account details and related private information. You agree to accept this responsibility and ensure your account and its related details are maintained securely at all times and all necessary steps are taken to prevent misuse of your account. You should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner. You agree and acknowledge that any use of the Site and related services offered and/or any access to private information, data or communications using your account and password shall be deemed to be either performed by you or authorized by you as the case may be. You agree to be bound by any access of the Site and/or use of any services offered by the Site (whether such access or use are authorized by you or not). You agree that we shall be entitled (but not obliged) to act upon, rely on or hold you solely responsible and liable in respect thereof as if the same were carried out or transmitted by you. You further agree and acknowledge that you shall be bound by and agree to fully indemnify us against any and all losses arising from the use of or access to the Site through your account.</p>
                            <p>Please ensure that the details you provide us with are correct and complete at all times. You are obligated to update details about your account in real time by accessing your account online. For pieces of information you are not able to update by accessing Your Account on the Site, you must inform us via our customer service communication channels to assist you with these changes. We reserve the right to refuse access to the Site, terminate accounts, remove or edit content at any time without prior notice to you. We may at any time in our sole and absolute discretion, request that you update your Personal Data or forthwith invalidate the account or related details without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by or caused by you or arising out of or in connection with or by reason of such request or invalidation. You hereby agree to change your password from time to time and to keep your account secure and also shall be responsible for the confidentiality of your account and liable for any disclosure or use (whether such use is authorised or not) of the username and/or password</p>
                            <h6 className="fw-normal mt-3 text-dark">B. PRIVACY</h6>
                            <p>Please review our Privacy Agreement, which also governs your visit to the Site. The personal information / data provided to us by you or your use of the Site will be treated as strictly confidential, in accordance with the Privacy Agreement and applicable laws and regulations. If you object to your information being transferred or used in the manner specified in the Privacy Agreement, please do not use the Site.</p>
                            <h6 className="fw-normal mt-3 text-dark">C. PLATFORM FOR COMMUNICATION</h6>
                            <p>You agree, understand and acknowledge that the Site is an online platform that enables you to purchase products listed at the price indicated therein at any time from any location using a payment method of your choice. You further agree and acknowledge that we are only a facilitator and cannot be a party to or control in any manner any transactions on the Site or on a payment gateway as made available to you by an independent service provider. Accordingly, the contract of sale of products on the Site shall be a strictly bipartite contract between you and the sellers on our Site while the payment processing occurs between you, the service provider and in case of prepayments with electronic cards your issuer bank. Accordingly, the contract of payment on the Site shall be strictly a bipartite contract between you and the service provider as listed on our Site.</p>
                            <h6 className="fw-normal mt-3 text-dark">D. CONTINUED AVAILABILITY OF THE SITE</h6>
                            <p>We will do our utmost to ensure that access to the Site is consistently available and is uninterrupted and error-free. However, due to the nature of the Internet and the nature of the Site, this cannot be guaranteed. Additionally, your access to the Site may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.</p>
                            <h6 className="fw-normal mt-3 text-dark">E. LICENSE TO ACCESS THE SITE</h6>
                            <p>We require that by accessing the Site, you confirm that you can form legally binding contracts and therefore you confirm that you are at least 18 years of age or are accessing the Site under the supervision of a parent or legal guardian. We grant you a non-transferable, revocable and non-exclusive license to use the Site, in accordance with the Terms and Conditions described herein, for the purposes of shopping for personal items and services as listed to be sold on the Site. Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by us in advance. If you are registering as a business entity, you represent that you have the authority to bind that entity to this User Agreement and that you and the business entity will comply with all applicable laws relating to online trading. No person or business entity may register as a member of the Site more than once. Any breach of these Terms and Conditions shall result in the immediate revocation of the license granted in this paragraph without notice to you.</p>
                            <p>Content provided on this Site is solely for informational purposes. Product representations including price, available stock, features, add-ons and any other details as expressed on this Site are the responsibility of the vendors displaying them and is not guaranteed as completely accurate by us. Submissions or opinions expressed on this Site are those of the individual(s) posting such content and may not reflect our opinions.</p>
                            <p>We grant you a limited license to access and make personal use of this Site, but not to download (excluding page caches) or modify the Site or any portion of it in any manner. This license does not include any resale or commercial use of this Site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this Site or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
                            <p>This Site or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent by us as may be applicable.</p>
                            <p>You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) without our express written consent. You may not use any Meta tags or any other text utilizing our name or trademark without our express written consent, as applicable. Any unauthorized use terminates the permission or license granted by us to you for access to the Site with no prior notice. You may not use our logo or other proprietary graphic or trademark as part of an external link for commercial or other purposes without our express written consent, as may be applicable.</p>
                            <p>You agree and undertake not to perform restricted activities listed within this section; undertaking these activities will result in an immediate cancellation of your account, services, reviews, orders or any existing incomplete transaction with us and in severe cases may also result in legal action</p>
                            <p>Refusal to comply with the Terms and Conditions described herein or any other guidelines and policies related to the use of the Site as available on the Site at all times.</p>
                            <p>Impersonate any person or entity or to falsely state or otherwise misrepresent your affiliation with any person or entity.</p>
                            <p>Use the Site for illegal purposes.</p>
                            <p>Attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Platform or Services.</p>
                            <p>Interfere with another’s utilization and enjoyment of the Site;</p>
                            <p>Post, promote or transmit through the Site any prohibited materials as deemed illegal by The Islamic Republic of Pakistan.</p>
                            <p>Use or upload, in any way, any software or material that contains, or which you have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Site’s data or damage or interfere with the operation of another Customer’s computer or mobile device or the Site and use the Site other than in conformance with the acceptable use policies of any connected computer networks, any applicable Internet standards and any other applicable laws.</p>
                            <h6 className="fw-normal mt-3 text-dark">F. YOUR CONDUCT</h6>
                            <p>You must not use the website in any way that causes, or is likely to cause, the Site or access to it to be interrupted, damaged or impaired in any way. You must not engage in activities that could harm or potentially harm the Site, its employees, officers, representatives, stakeholders or any other party directly or indirectly associated with the Site or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not us, are responsible for all electronic communications and content sent from your computer to us and you must use the Site for lawful purposes only. You are strictly prohibited from using the Site</p>
                            <p>For fraudulent purposes, or in connection with a criminal offense or other unlawful activity</p>
                            <p>to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Pakistan or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any “spam”.</p>
                            <p>Use the Site for illegal purposes.</p>
                            <p>To cause annoyance, inconvenience or needless anxiety</p>
                            <p>For any other purposes that is other than what is intended by us</p>
                            <h6 className="fw-normal mt-3 text-dark">G. YOUR SUBMISSION</h6>
                            <p>Anything that you submit to the Site and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, “Submissions”) will become our sole and exclusive property and shall not be returned to you. In addition to the rights applicable to any Submission, when you post comments or reviews to the Site, you also grant us the right to use the name that you submit, in connection with such review, comment, or other content. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may, but shall not be obligated to, remove or edit any Submissions without any notice or legal course applicable to us in this regard</p>
                            <h6 className="fw-normal mt-3 text-dark">H. CLAIMS AGAINST OBJECTIONABLE CONTENT</h6>
                            <p>We list thousands of products for sale offered by numerous sellers on the Site and host multiple comments on listings, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, we operate on a “claim, review and takedown” basis. If you believe that any content on the Site is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of Pakistan or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (” objectionable content “), please notify us immediately by following by writing to us on info@Bookshop.com.pk We will make all practical endeavours to investigate and remove valid objectionable content complained about within a reasonable amount of time.</p>
                            <p>Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of objectionable content party, instances of objection, proof of objection amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes.</p>
                            <h6 className="fw-normal mt-3 text-dark">I. CLAIMS AGAINST INFRINGING CONTENT</h6>
                            <p>We respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please write to us at info@Bookshop.com.pk and we will make all reasonable efforts to address your concern within a reasonable amount of time. Please ensure to provide your name, address, contact information and as many relevant details of the claim including name of infringing party, instances of infringement, proof of infringement amongst other. Please note that providing incomplete details will render your claim invalid and unusable for legal purposes. In addition, providing false or misleading information may be considered a legal offense and may be followed by legal proceedings.</p>
                            <p>We also respect a manufacturer’s right to enter into exclusive distribution or resale agreements for its products. However, violations of such agreements do not constitute intellectual property rights infringement. As the enforcement of these agreements is a matter between the manufacturer, distributor and/or respective reseller, it would not be appropriate for us to assist in the enforcement of such activities. While we cannot provide legal advice, nor share private information as protected by the law, we recommend that any questions or concerns regarding your rights may be routed to a legal specialist.</p>
                            <h6 className="fw-normal mt-3 text-dark">J. TRADEMARKS AND COPYRIGHTS</h6>
                            <p>Bookshop.com.pk, Book Shop logo, W for Book Shop logo, Book Shop, Book Shop Fashion, Book Shop Basics and other marks indicated on our Site are trademarks or registered trademarks in the relevant jurisdiction(s). Our graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress and may not be used in connection with any product or service that does not belong to us or in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits us. All other trademarks that appear on this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.</p>
                            <p>All intellectual property rights, whether registered or unregistered, in the Site, information content on the Site and all the website design, including, but not limited to text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain our property. The entire contents of the Site also are protected by copyright as a collective work under Pakistani copyright laws and international conventions. All rights are reserved.</p>
                            <h6 className="fw-normal mt-3 text-dark">K. DISCLAIMER</h6>
                            <p>You acknowledge and undertake that you are accessing the services on the Site and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the Site. We shall neither be liable nor responsible for any actions or inactions of sellers nor any breach of conditions, representations or warranties by the sellers or manufacturers of the products nor hereby expressly disclaim and any all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between you and the sellers or manufacturers of the products.</p>
                            <p>We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content (including product or pricing information and/or specifications) on the Site. While we have taken precautions to avoid inaccuracies in content, this Site, all content, information (including the price of products), software, products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the Site. At no time shall any right, title or interest in the products sold through or displayed on the Site vest with us nor shall Book Shop have any obligations or liabilities in respect of any transactions on the Site.</p>
                            <p>We shall neither be liable or responsible for any actions or inactions of any other service provider as listed on our Site which includes but is not limited to payment providers, instalment offerings, warranty services amongst others.</p>
                            <h6 className="fw-normal mt-3 text-dark">L. INDEMNITY</h6>
                            <p>You shall indemnify and hold harmless Book Shop as owned by Book Shop Singapore Private Limited, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney’s fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms and Conditions or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.</p>
                            <p>You hereby expressly release Book Shop as owned by Book Shop Singapore Private Limited and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the sellers or other service providers and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.</p>
                            <h6 className="fw-normal mt-3 text-dark">M. THIRD PARTY BUSINESSES</h6>
                            <p>Parties other than Book Shop and its affiliates may operate stores, provide services, or sell product lines on the Site. For example, businesses and individuals offer products via Marketplace. In addition, we provide links to the websites of affiliated companies and certain other businesses. We are not responsible for examining or evaluating, and we do not warrant or endorse the offerings of any of these businesses or individuals, or the content of their websites. We do not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. You can tell when a third-party is involved in your transactions by reviewing your transaction carefully, and we may share customer information related to those transactions with that third-party. You should carefully review their privacy statements and related terms and conditions.</p>
                            <h6 className="fw-normal mt-3 text-dark">N. COMMUNICATING WITH US</h6>
                            <p>When you visit the Site, or send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the Site or by any other mode of communication we choose to employ. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website (and/or placement of your order) and agree to treat all modes of communication with the same importance.</p>
                            <p>Book Shop’s Buyer-Seller Interaction Policy outlines the standard of behavior that sellers need to follow when connecting with customers on the Question & Answer Section, Instant Messenger, and Product Review Platform (of Book Shop). Book Shop monitors all kinds of buyer-seller communications and is authorized to take action in case of any violations.</p>
                            <h6 className="fw-normal mt-3 text-dark">O. LOSSES</h6>
                            <p>We will not be responsible for any business or personal losses (including but not limited to loss of profits, revenue, contracts, anticipated savings, data, goodwill, or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the Site.</p>
                            <h6 className="fw-normal mt-3 text-dark">P. AMENDMENTS TO CONDITIONS OR ALTERATIONS OF SERVICE AND RELATED PROMISE</h6>
                            <p>We reserve the right to make changes to the Site, its policies, these terms and conditions and any other publicly displayed condition or service promise at any time. You will be subject to the policies and terms and conditions in force at the time you used the Site unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</p>
                            <h6 className="fw-normal mt-3 text-dark">Q. EVENTS BEYOND OUR CONTROL</h6>
                            <p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</p>
                            <h6 className="fw-normal mt-3 text-dark">R. WAIVER</h6>
                            <p>You acknowledge and recognize that we are a private commercial enterprise and reserve the right to conduct business to achieve our objectives in a manner we deem fit. You also acknowledge that if you breach the conditions stated on our Site and we take no action, we are still entitled to use our rights and remedies in any other situation where you breach these conditions.</p>
                            <h6 className="fw-normal mt-3 text-dark">S. TERMINATION</h6>
                            <p>In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions. Upon any termination of this Agreement, you shall immediately cease all access to and use of the Site and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Site in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Site shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the Site or with any terms, conditions, rules, policies, guidelines, or practices in operating the Site, your sole and exclusive remedy is to discontinue using the Site.</p>
                            <h6 className="fw-normal mt-3 text-dark">T. GOVERNING LAW AND JURISDICTION</h6>
                            <p>These terms and conditions are governed by and construed in accordance with the laws of The Islamic Republic of Pakistan. You agree, as we do, to submit to the exclusive jurisdiction of the courts in Karachi, Pakistan.</p>
                            <h6 className="fw-normal mt-3 text-dark">U. CONTACT US</h6>
                            <p>You may reach us here</p>
                            <h6 className="fw-normal mt-3 text-dark">V. OUR SOFTWARE</h6>
                            <p>Our software includes any software (including any updates or upgrades to the software and any related documentation) that we make available to you from time to time for your use in connection with the Site (the “Software”).</p>
                            <p>You may use the software solely for purposes of enabling you to use and enjoy our services as permitted by the Terms and Conditions and any related applicable terms as available on the Site. You may not incorporate any portion of the Software into your own programs or compile any portion of it in combination with your own programs, transfer it for use with another service, or sell, rent, lease, lend, loan, distribute or sub-license the Software or otherwise assign any rights to the Software in whole or in part. You may not use the Software for any illegal purpose. We may cease providing you service and we may terminate your right to use the Software at any time. Your rights to use the Software will automatically terminate without notice from us if you fail to comply with any of the Terms and Conditions listed here or across the Site. Additional third party terms contained within the Site or distributed as such that are specifically identified in related documentation may apply and will govern the use of such software in the event of a conflict with these Terms and Conditions. All software used in any of our services is our property and/or our affiliates or its software suppliers and protected by the laws of Pakistan including but not limited to any other applicable copyright laws.</p>
                            <p>When you use the Site, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile platform provider. Your use of these third party services may be subject to separate policies, terms of use, and fees of these third parties.</p>
                            <p>You may not, and you will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with our software whether in whole or in part, or create any derivative works from or of the Software.</p>
                            <p>In order to keep the Software up-to-date, we may offer automatic or manual updates at any time and without notice to you.</p>
                            <h6 className="fw-normal mt-5 text-dark"><b>3. CONDITIONS OF SALE (BETWEEN SELLERS AND CUSTOMERS)</b></h6>
                            <p>Please read these conditions carefully before placing an order for any products with the Sellers (“We” or “Our” or “Us”, wherever applicable) on the Site. These conditions signify your agreement to be bound by these conditions.</p>
                            <h6 className="fw-normal mt-4 text-dark">A. CONDITIONS RELATED TO SALE OF THE PRODUCT OR SERVICE</h6>
                            <p>This section deals with conditions relating to the sale of products or services on the Site.</p>
                            <h6 className="fw-normal mt-4 text-dark">B. THE CONTRACT</h6>
                            <p>Your order is a legal offer to the seller to buy the product or service displayed on our Site. When you place an order to purchase a product, any confirmations or status updates received prior to the dispatch of your order serves purely to validate the order details provided and in no way implies the confirmation of the order itself. The acceptance of your order is considered confirmed when the product is dispatched to you. If your order is dispatched in more than one package, you may receive separate dispatch confirmations. Upon time of placing the order, we indicate an approximate timeline that the processing of your order will take however we cannot guarantee this timeline to be rigorously precise in every instance as we are dependent on third party service providers to preserve this commitment. We commit to you to make every reasonable effort to ensure that the indicative timeline is met. All commercial/contractual terms are offered by and agreed to between you and the sellers alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. Book Shop does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the you and the Sellers. The seller retains the right to cancel any order at its sole discretion prior to dispatch. We will ensure that there is timely intimation to you of such cancellation via an email or sms. Any prepayments made in case of such cancellation(s), shall be refunded to you within the time frames stipulated here.</p>
                            <p>You confirm that the product(s) or service(s) ordered by you are purchased for your internal / personal consumption and not for commercial re-sale. You authorize us to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose for your orders on the Site. The Seller or the Site may cancel an order wherein the quantities exceed the typical individual consumption. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity that exceeds the typical individual consumption. What comprises a typical individual’s consumption quantity limit shall be based on various factors and at the sole discretion of the Seller or ours and may vary from individual to individual.</p>
                            <p>You may cancel your order at no cost any time before the item is dispatched to you.</p>
                            <p>Please note that we sell products only in quantities which correspond to the typical needs of an average household. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity typical for a normal household.</p>
                            <h6 className="fw-normal mt-4 text-dark">C. RETURNS</h6>
                            <p>Please review our Returns Policy for details.</p>
                            <h6 className="fw-normal mt-4 text-dark">D. PRICING, AVAILABILITY AND ORDER PROCESSING</h6>
                            <p>All prices are listed in Rupees and are inclusive of GST and are listed on the Site by the seller that is selling the product or service. Items in your Shopping Cart will always reflect the most recent price displayed on the item’s product detail page. Please note that this price may differ from the price shown for the item when you first placed it in your cart. Placing an item in your cart does not reserve the price shown at that time. It is also possible that an item’s price may decrease between the time you place it in your basket and the time you purchase it.</p>
                            <p>We do not offer price matching for any items sold by any seller on our Site or other websites.</p>
                            <p>We are determined to provide the most accurate pricing information on the Site to our users; however, errors may still occur, such as cases when the price of an item is not displayed correctly on the Site. As such, we reserve the right to refuse or cancel any order. In the event that an item is mispriced, we may, at our own discretion, either contact you for instructions or cancel your order and notify you of such cancellation. We shall have the right to refuse or cancel any such orders whether or not the order has been confirmed and your prepayment processed. If such a cancellation occurs on your prepaid order, our policies for refund will apply.</p>
                            <p>We list availability information for products listed on the Site, including on each product information page. Beyond what we say on that page or otherwise on the Site, we cannot be more specific about availability. Please note that dispatch estimates are just that. They are not guaranteed dispatch times and should not be relied upon as such. As we process your order, you will be informed by e-mail or sms if any products you order turn out to be unavailable.</p>
                            <p>Please note that there are cases when an order cannot be processed for various reasons. The Site reserves the right to refuse or cancel any order for any reason at any given time. You may be asked to provide additional verifications or information, including but not limited to phone number and address, before we accept the order.</p>
                            <p>In order to avoid any fraud with credit or debit cards, we reserve the right to obtain validation of your payment details before providing you with the product and to verify the personal information you shared with us. This verification can take the shape of an identity, place of residence, or banking information check. The absence of an answer following such an inquiry will automatically cause the cancellation of the order within a reasonable timeline. We reserve the right to proceed to direct cancellation of an order for which we suspect a risk of fraudulent use of banking instruments or other reasons without prior notice or any subsequent legal liability.</p>
                            <h6 className="fw-normal mt-4 text-dark">Refund Voucher</h6>
                            <p>Refund voucher can be redeemed on our Website, as full or part payment of products from our Website within the given timeline.</p>
                            <p>Refund voucher cannot be used from different account.</p>
                            <p>Vouchers are not replaceable if expired.</p>
                            <h6 className="fw-normal mt-4 text-dark">Promotional Vouchers</h6>
                            <p>Each issued promotional voucher (App voucher and New customer voucher) will be valid for use by a customer only once. Multiple usages changing the identity is illegal.</p>
                            <p>Promotional voucher is non-refundable and cannot be exchanged for cash in part or full and is valid for a single transaction only.</p>
                            <p>Promotional voucher may not be valid during sale or in conjunction with any special promotion.</p>
                            <p>Voucher will work only if minimum purchase amount and other conditions are met.</p>
                            <p>Book Shop reserves the right to vary or terminate the operation of any voucher at any time without notice.</p>
                            <p>Book Shop shall not be liable to any customer or household for any financial loss arising out of the refusal, cancellation or withdrawal of any voucher or any failure or inability of a customer to use a voucher for any reason.</p>
                            <p>Vouchers are not replaceable if expired.</p>
                            <h6 className="fw-normal mt-4 text-dark">Security and Fraud</h6>
                            <p>When you use a voucher, you warrant to Book Shop that you are the duly authorized recipient of the voucher and that you are using it in good faith.</p>
                            <p>If you redeem, attempt to redeem or encourage the redemption of voucher to obtain discounts to which you are not entitled you may be committing a civil or criminal offence</p>
                            <p>If we reasonably believe that any voucher is being used unlawfully or illegally we may reject or cancel any voucher/order and you agree that you will have no claim against us in respect of any rejection or cancellation. Book Shop reserves the right to take any further action it deems appropriate in such instances</p>
                            <h6 className="fw-normal mt-4 text-dark">E. RESELLING Book Shop PRODUCTS</h6>
                            <p>Reselling Book Shop products for business purpose is strictly prohibited. But Reselling can be performed If you get the permission from us first. If any unauthorized personnel is found committing the above act, legal action may be taken against him/her.</p>
                            <h6 className="fw-normal mt-4 text-dark">F. TAXES</h6>
                            <p>You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from the Site and you agree to bear any and all applicable taxes as per prevailing law.</p>
                            <h6 className="fw-normal mt-4 text-dark">G. COMMUNICATING WITH US</h6>
                            <p>When you visit the Site, or send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication we choose to employ. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website (and/or placement of your order) and agree to treat all modes of communication with the same importance.</p>
                            <h6 className="fw-normal mt-4 text-dark">H. LOSSES</h6>
                            <p>We will not be responsible for any business or personal losses (including but not limited to loss of profits, revenue, contracts, anticipated savings, data, goodwill, or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the website.</p>
                            <h6 className="fw-normal mt-4 text-dark">I. AMENDMENTS TO CONDITIONS OR ALTERATIONS OF SERVICE AND RELATED PROMISE</h6>
                            <p>We reserve the right to make changes to the Site, its policies, these terms and conditions and any other publicly displayed condition or service promise at any time. You will be subject to the policies and terms and conditions in force at the time you used the website unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</p>
                            <h6 className="fw-normal mt-4 text-dark">J. EVENTS BEYOND OUR CONTROL</h6>
                            <p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</p>
                            <h6 className="fw-normal mt-4 text-dark">K. WAIVER</h6>
                            <p>You acknowledge and recognize that we are a private commercial enterprise and reserve the right to conduct business to achieve our objectives in a manner we deem fit. You also acknowledge that if you breach the conditions stated on our Site and we take no action, we are still entitled to use our rights and remedies in any other situation where you breach these conditions.</p>
                            <h6 className="fw-normal mt-4 text-dark">L. GOVERNING LAW AND JURISDICTION</h6>
                            <p>These terms and conditions are governed by and construed in accordance with the laws of the Islamic Republic of Pakistan. You agree, as we do, to submit to the exclusive jurisdiction of the courts in Karachi.</p>
                            <h6 className="fw-normal mt-4 text-dark">M. REPRESENTATIONS AND WARRANTIES</h6>
                            <p>We do not make any representation or warranty as to specifics (such as quality, value, saleability, etc) of the products or services listed to be sold on the Site when products or services are sold by third parties. We do not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Site. We accept no liability for any errors or omissions, whether on behalf of itself or third parties.</p>
                            <p>We are not responsible for any non-performance or breach of any contract entered into between you and the sellers. We cannot and do not guarantee your actions or those of the sellers as they conclude transactions on the Site. We are not required to mediate or resolve any dispute or disagreement arising from transactions occurring on our Site.</p>
                            <p>We do not at any point of time during any transaction as entered into by you with a third party on our Site, gain title to or have any rights or claims over the products or services offered by a seller. Therefore, we do not have any obligations or liabilities in respect of such contract(s) entered into between you and the seller(s). We are not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>
                            <p>Pricing on any product(s) or related information as reflected on the Site may due to some technical issue, typographical error or other reason by incorrect as published and as a result you accept that in such conditions the seller or the Site may cancel your order without prior notice or any liability arising as a result. Any prepayments made for such orders will be refunded to you per our refund policy as stipulated here.</p>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default termsPolicy
