

const Error404 = () => {
    return (
        <>
            <center>
                <h1 className='display-1'>Error 404 Page Not Found</h1>
            </center>
        </>
    )
}

export default Error404

